/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';
import banner from '../../images/events/2024/angacom/ANGACOM_events-desktop-hero.jpg';
import mobileBanner from '../../images/events/2024/angacom/ANGACOM_events-mobile-hero.jpg';
import ogimage from '../../images/events/2024/angacom/ANGACOM_events-og-image.jpg';

export default function ANGACOM2024() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='ANGA COM 2024 - Witbe' isEvents>
            <SEO
                title='Meet Witbe in Germany at ANGA COM 2024!'
                ogDescription='Visit Witbe at Booth C45 in Cologne at ANGA COM 2024! Discover our Ad Monitoring and Matching technology for video service providers.'
                description='Discover how you can improve your QoE with our video testing and monitoring technology, now faster with more AI.'
                article='article'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={11} banner={mobileBanner} /> : <DesktopEventPage id={11} banner={banner} />}
        </Layout>
    )
}
